<template>
  <div class="custom-tree-container">
    <el-input placeholder="输入关键字进行过滤" v-model="filterText"> </el-input>

    <el-tree
      class="filter-tree"
      :data="data"
      :props="defaultProps"
      default-expand-all
      label="departmentName"
      :filter-node-method="filterNode"
      ref="tree"
    >
      <span class="custom-tree-node" slot-scope="{ node, data }">
        <span>{{ node.label }}</span>
        <span>
          <el-button type="text" size="mini" @click="() => choose(node, data)">选择</el-button>
        </span>
      </span>
    </el-tree>
  </div>
</template>


<script>
import {mapGetters} from "vuex";
export default {
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  computed: {
    ...mapGetters(['userInfo']),
    data() {
      return this.userInfo.dtoList || []
    }
  },
  mounted(){
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.departmentName.indexOf(value) !== -1;
    },
    choose(node, data){
        // const parent = node.parent;
        // const children = parent.data.children || parent.data;
        this.$emit('choose',data)
        // const index = children.findIndex(d => d.id === data.id);
        // children.splice(index, 1);
    },
  },

  data() {
    return {
      filterText: "",
      defaultProps: {
        children: "children",
        label: "departmentName",
      },
    };
  },
};
</script>

<style>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
.custom-tree-container {
  height: 500px;
  overflow: auto;
}
</style>
