<template>
  <div class="menus">
    <div class="header flex-space-between flex-center-v">
      <div class="left flex-column"><span>工程安全巡检系统</span></div>
      <div class="flex-center-v">
        <div>当前组织：{{companyName}}</div>
        <el-button class="" @click="chooseProgramme" style="margin:0 10px;background:#ffffff" size="small">切换组织</el-button>
        <el-dropdown trigger="click">
          <span class="el-dropdown-link">
            {{ info.nickname}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="changePwd">修改密码</el-dropdown-item>
            <el-dropdown-item @click.native="loginOut">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <el-menu
      :default-active="$route.path"
      class="el-menu-demo"
      mode="horizontal"
      background-color="#ffffff"
      text-color="#000000"
      router
    >
<!--      <el-menu-item index="/menus/home">首页</el-menu-item>-->
<!--      <el-menu-item index="/menus/nacelle">吊篮设置</el-menu-item>-->
      <el-menu-item index="/menus/security">安全检查</el-menu-item>
      <el-menu-item v-if="isCompany" index="/menus/analysis">数据分析</el-menu-item>
      <el-menu-item index="/menus/system">系统设置</el-menu-item>
    </el-menu>
    <div class="app-main">
      <transition name="fade-transform" mode="out-in">
        <router-view />
      </transition>
    </div>
    <!-- 修改密码 -->
    <el-dialog
      title="修改密码"
      v-if="isShowChangePwd"
      :visible.sync="isShowChangePwd"
      width="30%">
      <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
        <el-form-item label="旧密码" prop="oldPwd">
          <el-input type="password" v-model="ruleForm.oldPwd" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPwd">
          <el-input type="password" v-model="ruleForm.newPwd" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="rePwd">
          <el-input type="password" v-model="ruleForm.rePwd" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer flex-center">
        <el-button size="small" type="primary" @click="submitForm('ruleForm')">提交</el-button>
        <el-button size="small" @click="resetForm('ruleForm')">重置</el-button>
      </span>
    </el-dialog>
    <el-dialog
      v-if="ifShowChooseTree"
      title="组织切换"
      style="overflow:auto"
      :visible.sync="ifShowChooseTree"
      width="40%"
    >
      <chooseTree :quit="quit" @choose="choose"></chooseTree>
    </el-dialog>

  </div>
</template>

<script>
  import apis from './apis.js'
  import { accessToken } from "../../utils/local-cache"
  import chooseTree from './chooseTree'
  import {mapGetters} from "vuex";
  export default {

    name: 'menus',

    components: {
      chooseTree
    },

    props: {
    },

    data() {
      var checkAge = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('密码不能为空'))
        }
        callback()
      }
      var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'))
        } else {
          if (this.ruleForm.checkPass !== '') {
            this.$refs.ruleForm.validateField('checkPass')
          }
          callback()
        }
      }
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'))
        } else if (value !== this.ruleForm.newPwd) {
          callback(new Error('两次输入密码不一致!'))
        } else {
          callback()
        }
      }
      return {
        options: [],
        ifShowChooseTree:false,
        companyValue: '',
        companyName:'',
        info: {
          username: ''
        },
        isShowChangePwd: false,
        ruleForm: {
          oldPwd: '',
          newPwd: '',
          rePwd: ''
        },
        chooseTreeList:[],
        rules: {
          oldPwd: [
            { validator: checkAge, trigger: 'blur' }
          ],
          newPwd: [
            { validator: validatePass, trigger: 'blur' }
          ],
          rePwd: [
            { validator: validatePass2, trigger: 'blur' }
          ]
        }
      }
    },

    computed: {
      ...mapGetters(["optionItem"]),
      isCompany(){
        return this.optionItem && this.optionItem.departmentType == 'company';
      }
    },

    watch: {
      isCompany(nv){
        if (nv) return
        const path = this.$route.path
        if (path == '/menus/analysis') {
          this.$router.push('/menus/security')
        }
      }
    },

    created() {
      this.init()
    },

    methods: {
      async init() {
        const info = await apis.getUserInfo()
        this.info = info
        // this.options = info.dtoList

        this.$store.dispatch('settings/getUserInfo', info)
        if (info.dtoList[0]) {
          this.companyValue = info.dtoList[0].departmentCode
          this.companyName = info.dtoList[0].departmentName
          this.$store.dispatch('settings/changeOptionItem', info.dtoList[0])
          this.$store.dispatch('settings/changeCompanyId', info.dtoList[0].departmentCode)
        }
      },
      // 退出选择组织弹框
      quit(){
        this.ifShowChooseTree = false
      },
      // 选择node节点
      choose(item){
          this.companyValue = item.departmentCode
          this.companyName = item.departmentName
          this.$store.dispatch('settings/changeOptionItem', item)
          this.$store.dispatch('settings/changeCompanyId', item.departmentCode)
          this.ifShowChooseTree = false
      },
      changeValue(val) {
        // console.log(val)
        const data = this.options.find(e => {
          return e.departmentCode == val
        })
        this.$store.dispatch('settings/changeOptionItem', data)
        this.$store.dispatch('settings/changeCompanyId', data.departmentCode)
      },
      chooseProgramme(){
        this.ifShowChooseTree = true
      },
      loginOut() {
        this.$confirm('是否退出登录', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          await apis.loginOut().then(() => {
            accessToken.set('')
            this.$router.push({
              name: 'Login'
            })
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
      },
      changePwd() {
        this.isShowChangePwd = true
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.savePwd()
          } else {
            console.log('error submit!!')
            return false
          }
        });
      },
      async savePwd() {
        await apis.updatePwd(this.ruleForm)
        this.$message.success('操作成功')
        this.isShowChangePwd = false
      },
      resetForm(formName) {
        this.$refs[formName].resetFields()
      }
    }
  }
</script>

<style lang="stylus">
  .menus
    height 100%
    .header
      height 60px
      background #3396FB
      text-align left
      font-size 16px
      padding 0 50px 0 20px
      color #ffffff
      .left
        letter-spacing 1px
      .el-dropdown-link
        cursor pointer
        color #ffffff
      .el-dropdown
        font-size 18px
      .el-icon-arrow-down
        font-size 14px
    .app-main
      height calc(100%  - 116px)
      width 100%
      overflow hidden
      padding 10px 20px
      >div
        height 100%
    // .el-menu
      // background-color #f5f5f5 !important
    .el-menu--horizontal>.el-menu-item
      font-size 18px
      width 200px
      // background-color #f5f5f5 !important
      height 55px !important
      line-height 55px !important
    .el-menu.el-menu--horizontal
      border-bottom none
    .el-tabs
      height 100%
      display flex
      flex-direction column
    .el-tabs__header
      background #ffffff
      border-radius 5px
      height 45px
    .el-tabs__content
      flex 1
      height 0
      border-radius 8px
    .el-tab-pane
      height 100%
      background #ffffff
    .el-tabs__nav-scroll
      padding-left 25px
    .el-tabs__item
      font-size 16px
      height 45px
      line-height 45px
    .el-table td, .el-table th
      padding: 8px 0 !important
    .el-dialog__wrapper
      display: flex
      justify-content: center
      align-items: center
    .el-dialog
      margin: 0 !important
      .el-dialog__header
        background #3396fb
        display flex
        padding 15px 20px
        .el-dialog__title
        .el-dialog__headerbtn .el-dialog__close
          color #ffffff
      .el-dialog__body
        padding 20px 20px 0
</style>
